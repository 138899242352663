import React from 'react'
import MetaImage from '../../assets/images/story/smartly/cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import SmartlyBody from '../../components/story/smartly/Content'
import SmartlyHeader from '../../components/story/smartly/Header'

const SmartlyPage = () => {
  return (
    <Layout
      title="Smartly case study"
      description="Smartly has been using Swarmia since 2021 to foster a culture of continuous improvement, empower teams with data-driven insights, and eliminate hours of manual work collecting and analyzing engineering metrics.
"
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <SmartlyHeader />
        <QuoteBlock person="fred" />
        <SmartlyBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default SmartlyPage
