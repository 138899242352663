import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const SmartlyHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Smartly"
      heading="How Smartly builds a data-driven engineering culture at scale"
      subheading="Smartly has been using Swarmia since 2021 to foster a culture of continuous improvement across their engineering and product organizations, empower teams with insights, and eliminate hours of manual work collecting and analyzing engineering metrics.
"
      featured={featured}
    />
  )
}

export default SmartlyHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/smartly/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
